.homepage-card {
  background-color: white;
  border-radius: 1rem;
  height: 200px;
  margin-top: auto;
  /* flex-basis: 0; */
}

.card-text {
  font-size: 22px;
}

.funfact-text {
  color: #383C3A;
  /* font-weight: 300; */
  font-size: 20px;
}

.funfact-more-text {
  color: #447189;
  font-weight: 800;
}

.card-image {
  height: 7rem;
  width: 7rem;
  /* background: url('../../img/homepagebackground.jpg') no-repeat center center fixed; */
  top: -25%;
  border-radius: 0.5rem;
  background-color: #FEFEFE;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.5);
}

.card-title  {
  color: #67a2bd;
  font-weight: 800;
}