.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-background {
  background: url('img/background.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* Popup override */
@import url(https://fonts.googleapis.com/css?family=Roboto);

.esri-popup {
  border-radius: 0.5rem; 
  font-family: Roboto;
  align-items: center !important;
}

.esri-popup__main-container {
  border-radius: 0.5rem; 
  max-height: none !important; 
}

.esri-popup__header {
  background-color: #447189;
  color: white;
  font-family: Roboto;
  border-radius: 0.5rem 0.5rem 0 0;  
}

.esri-popup__button--dock {
  display: none !important;
}

.esri-icon-close {
  color: white;
}

.esri-icon-default-action {
  display: none !important;
}

.esri-popup__inline-actions-container {
  padding: 10px;
}

.esri-popup__action {
  background-color: rgba(247,247,247,0.8);
  padding: 10px !important;
    border: 2px solid #447189;
    border-radius: 7px !important;
    color: #447189 !important;
    font-size: 15px !important;
    font-family: Roboto;
    font-weight: bold !important;
  max-width: none !important;
}

.esri-popup__navigation {
  background-color: white !important;
}

.esri-popup__pagination-previous {
  background-color: rgba(247,247,247,0.8);
  padding: 10px !important;
  margin: 0 10px 0 0 !important;
    border: 2px solid #447189;
    border-radius: 7px !important;
    color: #447189 !important;
}

.esri-popup__pagination-next {
  background-color: rgba(247,247,247,0.8);
  padding: 10px !important;
  margin: 0 0 0 10px !important;
    border: 2px solid #447189;
    border-radius: 7px !important;
    color: #447189 !important;
}

.esri-popup__feature-menu-button {
  font-size:  15px !important;
  color: #447189 !important;
}

.esri-popup__footer {
  order: 1;
}

.esri-search {
  position: absolute !important;
  left: 100px !important;
}