@import url(https://fonts.googleapis.com/css?family=Roboto);

.article-card {
      flex: 0 0 33%;
}

.article-card-content {
      font-family: Roboto;
      color: white;
}

.article-popup {
   width: 400px;
   height: auto;
   background-color: white;   
   border-radius: 0.5rem;
}

.article-modal {
      width: 80%;
      height: 80%;
      background-color: white;   
      border-radius: 0.5rem;
   }
   

.article-header {
      background-color: #447189;
      height: 2rem;
      border-radius: 0.5rem 0.5rem 0 0;
      color: white;
}

.article-popup-button {
      background-color: rgba(247,247,247,0.8);
      padding: 10px !important;
        border: 2px solid #447189;
        border-radius: 7px !important;
        color: #447189 !important;
        font-size: 15px !important;
        font-family: Roboto;
        font-weight: bold !important;
        cursor: pointer;
        margin-right: 1rem;
}