@import url(https://fonts.googleapis.com/css?family=Roboto);

.sidebar-back {
      background-color: rgba(247,247,247,0.8);
      border: 2px solid #447189;
      border-radius: 10px;
      padding: 15px;
      height: 70px;
      width: 70px;
}

.sidebar-filter-active {
      background-color: rgba(247,247,247,0.8);
      border: 2px solid #447189;
      border-radius: 15px;
      color: #447189;
      font-family: Roboto;
      font-weight: bold;
}

.sidebar-text-back {
      color: #447189;
      font-family: Roboto;
      font-size: 33px;
      font-weight: 100;
}

.sidebar-filter-text {
      font-family: Roboto;
      color: #447189;
      font-weight: bol;
}