@import url(https://fonts.googleapis.com/css?family=Roboto:100);

.layerlist-header {
      height: auto;
      font-family: Roboto;
      /* font-weight: 100; */
      color: #447189;
      /* font-weight: lighter; */
      font-weight: 400;
}

.layerlist-close {
      border-radius: 10px;
      padding: 15px;
      background-color: rgba(247,247,247,1);
      border: 2px solid #447189;
      font-size: 25px;
      color: #447189;
      padding: 15px 30px 15px 30px;
}

.layerlist-container {
      width: 500px;
      background-color: #F3F3F3;
}

.layerlist-icon-open {
      border: 2px solid #447189;
      border-radius: 10px;
      padding: 15px;
      height: 70px;
      width: 70px;
      background-color: rgba(247,247,247,1);
      /* top: 40px; */
}

.layer-row {
      padding: 5px 10px 5px 10px;
      margin-top: 7px;
      margin-bottom: 7px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 2px 2px rgba(0,0,0,0.1);
}

.basemapContainer {
      position: absolute !important;
      bottom: 0 !important;
      margin-bottom: 20px;
      background-color: #F3F3F3 !important;
}

.esri-basemap-gallery__item-thumbnail{
      height: 100px !important;
      width: 100px !important;
}

.esri-basemap-gallery__item-title {
      font-size: 20px !important;
      font-family: Roboto !important;
      color: black !important;
}

.disabled {
      opacity: 0.5;
}