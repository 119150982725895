@import url(https://fonts.googleapis.com/css?family=Roboto);

.theme-container {
      width: 30%;
      background-color: #F3F3F3;
      font-family: Roboto;
}

.theme-header-text {
      color: white;
}

.theme-close-btn {
      background-color: white;
      border: 2px solid #447189;
      border-radius: 15px;
      color: #447189;
      font-family: Roboto;
      padding: 15px;
      margin: 10px;
      font-weight: 800;
      cursor: pointer;
}