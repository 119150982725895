@import url(https://fonts.googleapis.com/css?family=Roboto:100);

.theme-allthemes-container {
      transition: top 0.5s;
      /* transition: bottom 1s; */
}

.theme-cards {
      font-family: Roboto;
      width: 400px;
      transition: 1s;
}

.theme-card {
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}

.theme-divider {
      width: 15%;
      height: 100%;
      background-color: #447189;
}

.theme-icon-close {
      height: 15px;
}

.theme-rotated {
      /* display: inline-block; */
      transform: rotate(270deg);
      color: white;
}

.theme-card-text {
      background-color: white;
      color: #447189;
      font-size: 20px;
}

.theme-icon-open {
      border: 2px solid #447189;
      border-radius: 10px;
      padding: 15px;
      height: 70px;
      width: 70px;
      background-color: rgba(247,247,247,1);
}

.theme-allthemes-header {
      height: 20%;
      background-color: white;
      font-family: Roboto;
      /* font-weight: 100; */
      color: #383C3A;
      font-weight: lighter;
}

.theme-allthemes {
      background-color: rgba(247,247,247,0.8);
      height: 80%;
}

.theme-btn-close {
      border-radius: 10px;
      padding: 15px;
      background-color: rgba(247,247,247,1);
      border: 2px solid #447189;
      font-weight: 800;
      font-size: 25px;
      color: #447189;
      padding: 15px 30px 15px 30px;
}

.theme-card-container {
      flex: 0 0 25%;
      padding: 1rem;
}