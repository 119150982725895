.timefilter-container {
      left: 100%;
      width: 200%;
      background-color: white;
      border-left: 1px solid lightgray;overflow: auto;
}

.timefilter-timeperiod {
      border-bottom: 1px;
      border-style: solid;
      border-color: #e2e8f0;
}

.timefilter-title {
      color: #447189;
      font-weight: 500;
}

.filter-close-btn {
      background-color: rgba(247,247,247,0.8);
      border: 2px solid #447189;
      border-radius: 15px;
      color: #447189;
      font-family: Roboto;
      font-weight: bold;
      padding: 10px;
      cursor: pointer;
      margin-left: 20px;
      margin-top: 20px;
}