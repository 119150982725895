@import url(https://fonts.googleapis.com/css?family=Roboto);

.homepage-header {
  height: 25%;
}

.homepage-explore {
  height: 35%;
  font-family: Roboto;
  color: #383C3A;
}

.homepage-explore-container {
  height: 100%;
  background-color: white;
  border-radius: 1rem;
  background: url('../img/homepagebackground.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.homepage-cards {
  height: 30%;
  font-family: Roboto;
}

.homepage-footer {
  height: 10%;
}

.homepage-footer-container {
  height: 100%;
  border-radius: 1rem;
  background-color: #67a2bd;
}

.homepage-mascot {
  position: absolute;
  right: 2rem;
  top: -1rem;
  height: 90%;
}